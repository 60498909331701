<template>
  <b-sidebar
    id="sidebar-profile-edit"
    ref="sidebarProfileEdit"
    v-model="isShowSidebar"
    aria-labelledby="sidebar-action-header"
    right
    backdrop
    shadow
    no-header
    width="80vw"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter">Create New Customer</b-col>
      </b-row>
      <div class="py-3">
        <div class="label-header">Information</div>
        <b-row>
          <b-col sm="6" class="mt-3">
            <div class="main-label">Customer Type</div>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="customer-type"
                v-model="form.is_company"
                :options="[
                  { value: 0, text: 'Personal' },
                  { value: 1, text: 'Company' },
                ]"
                :aria-describedby="ariaDescribedby"
                @change="(val) => handleTypeChange(val)"
                name="customer-type-options"
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <div class="break-normal"></div>
        </b-row>
        <template>
          <Company
            v-show="form.is_company == 1"
            :formMain="form"
            ref="company"
            :FieldListMain="FieldList"
            :socialList="socialList"
            :v="$v"
            @getData="$emit('getData')"
            @setForm="(val) => $emit('setForm', val)"
            :limit="limit"
          />
          <Personal
            v-show="form.is_company == 0"
            ref="personal"
            :formMain="form"
            :FieldList="FieldList"
            :socialList="socialList"
            :key="updateKey"
            :v="$v"
            @getData="$emit('getData')"
            :limit="limit"
          />
        </template>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import Company from "./Company";
import Personal from "./Personal";
import {
  required,
  requiredIf,
  email,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
const Script_checkID = (id) => {
  id = String(id);

  if (id == "null") {
    return true;
  }
  if (id.trim() == "") {
    return true;
  }
  let i = 0,
    sum = 0;
  if (id.substring(0, 1) == 0) return false;
  for (i = 0, sum = 0; i < 12; i++) sum += parseFloat(id.charAt(i)) * (13 - i);
  if ((11 - (sum % 11)) % 10 != parseFloat(id.charAt(12))) return false;
  return true;
};
const telephoneFormat = (val) => {
  let validate = new RegExp(/^\d+(\-\d{1,20})?$/);

  if (validate.test(val)) return true;
  return false;
};
const TelephoneMinLength = (val, e, a) => {
  if (!val) return true;
  if (val.split("-")[0].length < 9 && e.telephone_country_id == 1) return false;
  return true;
  // return minLength(val);
};
export default {
  components: {
    Company,
    Personal,
  },
  props: {
    isConsentList: {
      required: false,
      type: Array,
    },
    limit: {
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      image: "",
      acceptType: "image/jpeg, image/png",
      isLoadingImage: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      isLoadingImage: false,
      isDisable: false,
      prefixOption: [],
      genderChoice: [],
      optionalTelephone: [],
      errorOptional: false,
      form: {
        user_guid: "",
        first_name_th: null,
        last_name_th: null,
        first_name_en: null,
        last_name_en: null,
        nickname: "",
        email: "",
        telephone: this.$route.params.id == 0 ? "" : this.$route.params.id,
        member_level_id: 0,
        picture: null,
        birthday: null,
        line_id: null,
        line_ref: null,
        home_address: null,
        branch_name: "",
        branch_no: "",
        gender: "",
        zip_code: null,
        province: null,
        town: null,
        alley: null,
        road: null,
        subdistrict: null,
        district: null,
        is_consent: 0,
        id_card: "",
        prefix: "",
        user_custom_field: [],
        user_tag: [],
        optional_telephone: "",
        company_type: "",
        company_information: "จดทะเบียน",
        company_branch_type: "สำนักงานใหญ่",
        is_company: 0,
        telephone_country_id: 1,
        registerTypeId: 3,
      },
      customerTypeList: [],
      FieldList: [],
      companyFieldList: [],
      updateKey: 0,
      socialList: [],
    };
  },
  validations: {
    form: {
      first_name_th: { required },
      last_name_th: {
        required: requiredIf(function (item) {
          return this.form.is_company == 0;
        }),
      },
      company_type: {
        required: requiredIf(function (item) {
          return this.form.is_company == 1;
        }),
      },
      prefix: {
        required: requiredIf(function (item) {
          return this.form.is_company == 1;
        }),
      },
      branch_name: {
        required: requiredIf(function (item) {
          return (
            this.form.is_company == 1 && this.form.company_branch_type == "สาขา"
          );
        }),
      },
      branch_no: {
        required: requiredIf(function (item) {
          return (
            this.form.is_company == 1 && this.form.company_branch_type == "สาขา"
          );
        }),
      },
      telephone: {
        required,
        telephoneFormat,
        TelephoneMinLength,
      },
      email: { email },
      id_card: {
        Script_checkID,
        maxLength: maxLength(13),
        minLength: minLength(13),
        numeric,
      },
    },
  },
  mounted() {
    this.getSocialMedia();
  },
  computed: {},
  methods: {
    async show() {
      this.$v.form.$reset();
      if (this.form.is_company == 0) {
        await this.getFields(1);
        await this.$refs.personal.show();
      }
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    async getSocialMedia() {
      const result = await this.axios(`customer/GetSocialMediaListAll`);

      // for (const social of result.data.detail) {
      //   social.social_media_type_id = social.id;
      //   // console.log(social);
      //   const $social = this.form.social.find(
      //     (el) => social.id == el.social_media_type_id
      //   );
      //   if ($social) {
      //     social.id = $social.id;
      //     social.value = $social ? $social.value : "";
      //   } else {
      //     social.id = 0;
      //   }
      // }
      this.socialList = result.data.detail;
    },
    async getFields(type = 1) {
      this.$bus.$emit("showLoading");

      this.form = {
        user_guid: "",
        first_name_th: null,
        last_name_th: null,
        first_name_en: null,
        last_name_en: null,
        nickname: "",
        email: "",
        telephone: this.$route.params.id == 0 ? "" : this.$route.params.id,
        member_level_id: 0,
        picture: null,
        birthday: null,
        line_id: null,
        line_ref: null,
        home_address: null,
        branch_name: "",
        branch_no: "",
        gender: "",
        zip_code: null,
        province: null,
        town: null,
        alley: null,
        road: null,
        subdistrict: null,
        district: null,
        is_consent: 0,
        id_card: "",
        prefix: "",
        user_custom_field: [],
        user_tag: [],
        optional_telephone: "",
        company_type: "",
        company_information: "จดทะเบียน",
        company_branch_type: "สำนักงานใหญ่",
        is_company: type == 2 ? 1 : 0,
        telephone_country_id: 1,
        registerTypeId: 3,
      };
      if (type == 2) {
        if (this.companyFieldList.length == 0) {
          const { data } = await this.axios(`/setting/getcompanyform`);
          this.companyFieldList = data.detail.profileField;
        }
        this.FieldList = this.companyFieldList;
      } else {
        if (this.FieldList.length == 0)
          await this.$store.dispatch("getFieldProfiletypes");

        let data = this.$store.state.form.profileLists;
        this.choice = {
          province: await data.detail.fields.find(
            (el) => el.field_profile_type_id == 18
          ),
          district: await data.detail.fields.find(
            (el) => el.field_profile_type_id == 17
          ),
          subdistrict: await data.detail.fields.find(
            (el) => el.field_profile_type_id == 16
          ),
          prefix: await data.detail.fields.find(
            (el) => el.field_profile_type_id == 2
          ),
          gender: await data.detail.fields.find(
            (el) => el.field_profile_type_id == 21
          ),
        };
        this.FieldList = data.detail.fields;
      }
      this.$bus.$emit("hideLoading");
      return this.choice;
    },

    async submitAction() {
      if (this.form.is_company == 0) this.form = await this.$refs.personal.form;
      else this.form = await this.$refs.company.form;

      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      const result = await this.updateProfileDetail();
      if (result) this.hide();
    },
    async updateProfileDetail() {
      this.$bus.$emit("showLoading");
      var result = false;
      if (this.form.is_company == 0)
        result = await this.$refs.personal.updateProfileDetail();
      else result = await this.$refs.company.updateProfileDetail();
      this.$bus.$emit("hideLoading");
      return result;
    },
    async handleTypeChange(val) {
      //   await this.getFields();
      if (val == 0) {
        await this.getFields();
        this.$refs.personal.show();
        // this.updateKey += 1;
      } else {
        await this.getFields(2);
        this.$refs.company.show();
        // this.updateKey += 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  font-size: 17px;
}
.panel-bg-file-img {
  width: 50%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  margin-bottom: 15px;
  position: relative;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
.title-label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.consent-allow {
  color: #06c306;
}
.consent-cancel {
  color: red;
}
.f-right {
  float: right;
}
.point-box {
  margin: 0px;
  padding: 10px 5px;
  background-color: var(--theme-secondary-color);
}
.icon-to-point {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  margin-left: 5px;
  font-size: 10px;
  border-radius: 3px;
  padding: 3px 9px;
}
.title-point {
  color: gray;
}
.title-total-point {
  font-weight: 600;
  font-size: 17px;
}
</style>
